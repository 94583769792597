import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import { format } from 'date-fns';

const OPENED_STATUS = "opened";

class WeatherStatus extends Component {

	static contextType = I18nextContext;

	constructor(props) {
		super(props);
		this.state = {
			installations: {
				masca_path:{
					name: '-',
					status: '-'
				},
				masca_visitors_center:{
					name: '-',
					status: '-'
				},
				pedregales_visitors_center:{
					name: '-',
					status: '-'
				},
				masca_pier:{
					name: '-',
					status: '-'
				}
			},
			forecast:[],
			weather: {
				masca: {
					temperature: '-'
				}
			}
		}
	}
	
	componentDidMount() {
		axios.get(this.props.statusAPIURL, {
			params: {
				"x-api-key": this.props.apiKey
			},
			headers: {
				'accept': 'application/json',
				'accept-language': this.context.language
			}
		}).then((response) => {
			this.setState({installations: response.data.status.installations});
			this.setState({weather: response.data.status.weather});
			if (this.state.weather.masca.date) {
				this.setState(prevState => ({
					weather: {
					...prevState.weather,
					masca: {       
						...prevState.weather.masca,
						date: format(new Date(this.state.weather.masca.date.replace(' ', 'T')), 'dd/MM/yyyy')
					}
					}
				}))
			}
			this.setState({forecast: this.getForecastArray(response.data.status.forecast)});
		});
	}

	/**
	 * Método que devuelve el badge para el estado de las instalaciones según el estado de las mismas
	 * @param {*} status 	Estado de las instalaciones obtenido desde Ajax
	 * @param {*} t 		Función de traducciones
	 */
	renderInstallationStatus(status, t){
		let span = "";

		if (status === OPENED_STATUS){
			span = (<span className={`badge badge-primary`}>{t('Abierto')}</span>);
		} else {
			span = (<span className={`badge badge-secondary`}>{t('Cerrado')}</span>);
		}

		return span;
	}

	/**
	 * Devuelve un array con los días que mostrar en la tabla de Previsión a partir de un objeto JSON.
	 * @param {*} forecastObject 	Objeto JSON obtenido desde el API de Volcano Teide
	 */
	getForecastArray(forecastObject) {
		const sortedKeys = Object.keys(forecastObject).sort((a, b) => (a > b ? 1 : -1));
		let forecastArray = [];

		for(let i = 0; i < sortedKeys.length; i++) {
			forecastArray.push(forecastObject[sortedKeys[i]]);
		}

		return forecastArray;
	}

  	render() {
		const { t } = this.props;
		return 	<React.Fragment>
					<StaticQuery
						query={graphql`
							query StatusAPIURL {
								site {
									siteMetadata {
										roadStatusURL,
										tide1StatusURL,
										tide2StatusURL
									}
								}
							}
						`}
						render={data => (<>
							<div className={this.props.classes + ' masca-hoy'}>
								<div className={`card-deck pb-14px`}>
									<div className={`col-12 card panel`}>
										<h2 className={`cabecera text-center`}>{t('El tiempo')}&nbsp;<span className={`icon-tiempo`}></span></h2>
										<div className={`row`}>
											<div className={`col-12 col-lg-4 text-center`}>
												<div className={`mb-2 pt-1`}>
													<div>
														<strong>{t('Barranco de Masca')}</strong>
													</div>
													<div className={`texto-cabecera`}>
														{t('Hoy')}
													</div>
												</div>
												<div>
													<span className={`badge badge-light temperatura`}>{this.state.weather.masca.temperature}</span>
												</div>
												<div className={`mt-2`}>
													<div className={`texto-cabecera`}>
														{t('Humedad')}&nbsp;{this.state.weather.masca.humidity}
													</div>
													<div className={`texto-cabecera-sm`}>
														{t('Actualizado el')}&nbsp;{this.state.weather.masca.date}
													</div>
												</div>
											</div>
											<div className={`col-12 col-lg-7 offset-lg-1`}>
												<table className={`table table-sm table-borderless table-striped`}>
													<thead>
														<tr>
															<th scope="col">{t('Previsión')}</th>
															<th scope="col">&nbsp;</th>
															<th scope="col" className={`text-center`}>
																<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-plus-circle-fill"} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
																	<path fillRule={`evenodd`} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
																</svg>
															</th>
															<th scope="col" className={`text-center`}>
																<svg width="1em" height="1em" viewBox="0 0 16 16" className={"bi bi-dash-circle-fill"} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
																	<path fillRule={`evenodd`} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
																</svg>
															</th>
														</tr>
													</thead>
													<tbody>
														{this.state.forecast.map((item, i) => {
															return (<tr key={i}>
																		<th scope="row">
																			<span>{item.day}</span>
																		</th>
																		<td className={`text-center`}>
																			<span className={`icon-${item.icon}`}></span>
																		</td>
																		<td className={`text-center`}>
																			<span>{Math.round(item.temperatureMax)}</span>
																		</td>
																		<td className={`text-center`}>
																			<span>{Math.round(item.temperatureMin)}</span>
																		</td>
																	</tr>)
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className={`card-deck justify-content-between pb-14px`}>
									<div className={`col-12 col-lg-6 card panel`}>
										<div className={`py-2 px-4`}>
											<div className={`titulo mb-4 d-flex flex-row`}>
												<div className={`align-self-center`}>
													<span className={`icon-casa`}></span>
												</div>
												<div className={`ml-2 align-self-center`}>
													<span>{t('Estado de las instalaciones')}</span>
												</div>
											</div>
											<div className={`cuerpo`}>
												<table className={`table table-sm table-borderless`}>
													<tbody>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-casa-rellena`}></span>
															</th>
															<td>
																<span className={`nombre-seccion`}>{this.state.installations.masca_visitors_center.name}</span>
															</td>
															<td className={`text-right`}>
																{this.renderInstallationStatus(this.state.installations.masca_visitors_center.status, t)}
															</td>
														</tr>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-casa-rellena`}></span>
															</th>
															<td>
																<span className={`nombre-seccion`}>{this.state.installations.pedregales_visitors_center.name}</span>
															</td>
															<td className={`text-right`}>
																{this.renderInstallationStatus(this.state.installations.pedregales_visitors_center.status, t)}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className={`col-12 col-lg-6 card panel`}>
										<div className={`py-2 px-4`}>
											<div className={`titulo mb-4 d-flex flex-row`}>
												<div className={`align-self-center`}>
													<span className={`icon-excursionista`}></span>
												</div>
												<div className={`ml-2 align-self-center`}>
													<span>{t('Estado del camino y el embarcadero')}</span>
												</div>
											</div>
											<div className={`cuerpo`}>
												<table className={`table table-sm table-borderless`}>
													<tbody>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-caminos`}></span>
															</th>
															<td>
																<span className={`nombre-seccion`}>{this.state.installations.masca_path.name}</span>
															</td>
															<td className={`text-right`}>
																{this.renderInstallationStatus(this.state.installations.masca_path.status, t)}
															</td>
														</tr>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-casa-rellena`}></span>
															</th>
															<td>
																<span className={`nombre-seccion`}>{this.state.installations.masca_pier.name}</span>
															</td>
															<td className={`text-right`}>
																{this.renderInstallationStatus(this.state.installations.masca_pier.status, t)}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className={`card-deck justify-content-between pb-14px`}>
									<div className={`col-12 col-lg-6 card panel`}>
										<div className={`py-2 px-4`}>
											<div className={`titulo mb-4 d-flex flex-row`}>
												<div className={`align-self-center`}>
													<span className={`icon-carretera`}></span>
												</div>
												<div className={`ml-2 align-self-center`}>
													<span>{t('Estado de las carreteras de acceso a Masca')}</span>
												</div>
											</div>
											<div className={`cuerpo`}>
												<div className={`text-center`}>
													<a href={data.site.siteMetadata.roadStatusURL} target="_blank" rel="noreferrer" className={`btn btn-sm btn-primary`}>{t('Consultar')}</a>
												</div>
											</div>
										</div>
									</div>
									<div className={`col-12 col-lg-6 card panel`}>
										<div className={`py-2 px-4`}>
											<div className={`titulo mb-4 d-flex flex-row`}>
												<div className={`align-self-center`}>
													<span className={`icon-olas`}></span>
												</div>
												<div className={`ml-2 align-self-center`}>
													<span>{t('Estado del cielo, viento y oleaje')}</span>
												</div>
											</div>
											<div className={`cuerpo`}>
												<table className={`table table-sm table-borderless`}>
													<tbody>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-olas-rellena`}></span>
															</th>
															<td>
															<span className={`nombre-seccion`}>{t('Playa de La Arena')}</span>
															</td>
															<td className={`text-right`}>
																<a href={data.site.siteMetadata.tide1StatusURL} target="_blank" rel="noreferrer" className={`btn btn-sm btn-primary`}>{t('Consultar')}</a>
															</td>
														</tr>
														<tr>
															<th className={`columna-icono`} scope="row">
																<span className={`icon-olas-rellena`}></span>
															</th>
															<td>
															<span className={`nombre-seccion`}>{t('Playa de La Ballenita')}</span>
															</td>
															<td className={`text-right`}>
																<a href={data.site.siteMetadata.tide2StatusURL} target="_blank" rel="noreferrer" className={`btn btn-sm btn-primary`}>{t('Consultar')}</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
							</>
						)}
					/>
				</React.Fragment>
  	}
}

export default withTranslation('WeatherStatus')(WeatherStatus);